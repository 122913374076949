import debounce from 'lodash-es/debounce'
import unitRepository from '../../api/repository/unitRepository'
import utils from '@/helpers/utils.js'
import {message} from '@/plugins/message'
import dayjs from 'dayjs'
import {cloneDeep, set} from 'lodash-es'
const examTypes = ['exercise', 'test', 'matrix', 'reading-test', 'listening-test']
const defaultState = () => {
  return {
    title: null,
    description: null,
    type: '',
    syllabus: [],
    id: null,
    // settings
    isDraft: false,
    hasDescription: false,
    hasAttachment: false,
    canEnableDiscussion: false,
    mustApproveDiscussion: false,

    // time settings range
    hasRangeTime: false,
    rangeTimeFromDate: dayjs().format('YYYY-MM-DD'),
    rangeTimeFromHour: '00:00',
    rangeTimeToDate: dayjs().format('YYYY-MM-DD'),
    rangeTimeToHour: '00:00',
    formRangeTime: true,

    //time settings publich
    hasPublicationTime: false,
    publicDate: dayjs().format('YYYY-MM-DD'),
    publicHour: '00:00',
    formPublicTime: true,
    //rules
    submitCount: false,
    isRankingHidden: false,
    hideResult: false,
    hideAnswer: false,
    enableDetectFocusout: false,
    hasMinScore: false,
    minScore: 2,

    //data of type
    data: {},

    //setting exam
    isPartition: false,

    isRandomQuestion: false,
    isUseFactor: false,
    isQuestionOneByOne: false,
    isDisablePrevious: false,
    shuffleAnswers: false,

    isResultReviewShown: false,
    isReportExam: false,

    selectedTime: 'unlimited',
    selectedTypeScore: '10-mark',
    expiredTime: 10,

    numberOfExam: 0,

    openScorePart: false,
    openTimePart: false,

    settingExams: {},
    reviewConfig: {},
    reviewConfigDraft: {},

    hasPlanPostUnit: false,
    postDate: 0,
    postTime: 0,
    formPlanPostTime: true,

    screenRatioHaft: false,

    isUpdateDataExam: false,

    //video
    isExternalLink: false,
    // flashcard
    flashcardType: 'default',
  }
}
const state = {
  isLoading: false,
  isInitDraft: true,
  isSubmit: false,
  loadingSubmit: false,
  // main attributes
  ...defaultState(),
}

const actions = {
  syncDraft: ({commit, dispatch, rootState}, data) => {
    const {id, isInitDraft, isDraft} = rootState.draftUnit
    if (id && !isInitDraft) {
      commit('setAttributes', data)
      if (isDraft) dispatch('syncDraftDebounce')
    }
  },
  syncDraftDebounce: debounce(async ({dispatch}) => {
    return dispatch('submitDraft')
  }, 3000),
  async submitDraft({commit, rootState, getters}) {
    commit('setIsLoading', true)
    const {title, description, type, syllabus, id, data, hasDescription, hasAttachment, isDraft} = rootState.draftUnit
    try {
      if (isDraft) {
        const unit = await unitRepository.saveDraftUnit(id, {
          settings: getters.getAllSettings,
          data: {
            [type]: data,
          },
          info: {
            title,
            description,
            type,
            syllabus,
            id,
          },
          dataSettings: {
            hasDescription,
            hasAttachment,
            ...getters.getDiscusion,
          },
          reviewConfig: getters.getReviewConfig,
        })
        commit('unit/setUnit', unit.data, {root: true})
        return unit.data
      }
    } catch (e) {
      console.log(e)
    } finally {
      commit('setIsLoading', false)
    }
  },
  async initDraft({commit, dispatch, rootState}, {unit}) {
    if (unit?.id) {
      commit('setInit', defaultState())
      commit('setAttributes', {name: 'loadingSubmit', value: false})
      let dataDraft = {}
      if (unit.settings?.isDraft && unit.draft) {
        const {dataSettings, settings, data, info, reviewConfig} = unit.draft
        const type = info.type ? info.type : unit.type
        const dataType = data && type ? data[type] : {}
        dataDraft = {
          ...dataSettings,
          ...settings,
          ...info,
          type,
          data: dataType,
          settingExams: utils.convertSettingsExamDraft({
            ...dataSettings,
            ...settings,
          }),
          reviewConfig: reviewConfig || {},
          reviewConfigDraft: cloneDeep(reviewConfig || {}),
        }
      } else {
        const {exam} = rootState.exam
        const {questions} = rootState.question
        const {data, settings, title, description, type, syllabus, id, reviewConfig} = unit
        const settingExams = examTypes.indexOf(type) > -1 ? exam?.settings : {}
        dataDraft = {
          ...data,
          ...settings,
          ...settingExams,
          title,
          description,
          type,
          syllabus,
          id,
          data: utils.breakDataForDraft(unit, exam, questions),
          settingExams: utils.convertSettingsExamDraft({...defaultState(), ...settingExams}),
          reviewConfig: reviewConfig || {},
          reviewConfigDraft: cloneDeep(reviewConfig || {}),
        }
      }
      commit('setInit', dataDraft)
      await utils.delay(500)
      if (rootState.draftUnit.id) dispatch('setIsInitDraft', false)
    }
  },
  setIsInitDraft({commit}, val) {
    commit('setIsInitDraft', val)
  },
  async onSubmitUnit({commit, dispatch}) {
    commit('setAttributes', {name: 'loadingSubmit', value: true})
    await dispatch('onUploadNewResource')
  },

  async onUploadNewResource({dispatch, getters, commit}) {
    const {hasAttachment, hasDescription, title, syllabus, description, id} = getters.getInfo
    const discussionSettings = getters.getDiscusion
    const rules = getters.getRules
    const basicInfo = {
      title,
      syllabus,
      description,
      id,
      data: {
        ...discussionSettings,
        hasMinScore: rules.hasMinScore,
        minScore: rules.minScore,
        hasAttachment,
        hasDescription,
      },
    }
    let res = await dispatch(!getters.getDraftType ? 'editUnit' : 'updateUnitWithDetail', {basicInfo})
    if (res === true)
      commit('setAttributes', [
        {name: 'isDraft', value: false},
        {name: 'isUpdateDataExam', value: false},
      ])
    commit('setAttributes', {name: 'loadingSubmit', value: false})
  },
  async editUnit({dispatch, getters}, {basicInfo}) {
    message.loading('Đang cập nhật...')
    const settings = {
      ...getters.getUnitSetting,
      isDraft: false,
    }
    try {
      const params = {
        ...basicInfo,
        settings,
      }
      await dispatch('unit/updateUnit', params, {root: true})
      message.success('Cập nhật thành công!')
      return true
    } catch (error) {
      message.error(error)
    }
  },
  async updateUnitWithDetail({getters, dispatch}, {basicInfo}) {
    const unitSetting = getters.getUnitSetting
    if (!unitSetting.rangeTimeFromDate) return
    const validateScore = await dispatch('validateScore')
    const validateRangeTime = await dispatch('validateRangeTime')
    const validatePublicTime = await dispatch('validatePublicTime')
    const validatePlanPost = await dispatch('validatePlanPost')
    if (!validateScore || !validatePlanPost || !validatePublicTime || !validateRangeTime) return false
    return await dispatch('updateUnitWithSettings', {basicInfo})
  },

  async updateUnitWithSettings({dispatch, rootState, getters}, {basicInfo}) {
    const {getIsUploading} = rootState.onlineCourseVideoLibrary
    const {type, isExternalLink, data, rangeTimeFromDate, flashcardType} = rootState.draftUnit
    const detailData = data
    let settings = getters.getUnitSetting
    let reviewConfig = getters.getReviewConfig
    const {course} = rootState.onlineCourse
    if (getIsUploading) {
      return message.error('Video đang tải lên, vui lòng đợi cho đến khi video tải lên xong!')
    }
    let unitData = {}
    const isChanged = utils.isUpdateDuration(type, course)
    if (isChanged) dispatch('updateDuration')
    if (!rangeTimeFromDate) return false
    unitData = {settings: {...settings, isDraft: false}, reviewConfig}
    if (examTypes.indexOf(type) > -1) {
      const examId = await dispatch('updateUnitExam')
      if (!examId) return false
      unitData = {...unitData, exam: examId}
    } else {
      if (!type.includes('flashcard')) unitData = {...unitData, ...detailData}
      else {
        unitData = {...unitData, settings: {...unitData.settings, flashcardType}}
        await dispatch('flashcard/setWordsForUnit', detailData, {root: true})
      }
    }
    try {
      const updateBody = {
        type,
        ...basicInfo,
        ...unitData,
        draft: {},
      }
      if (updateBody.type === 'video' || updateBody.type === 'vimeo') {
        if (isExternalLink) {
          updateBody.settings = {
            ...updateBody.settings,
            isExternalLink,
          }
          if (!updateBody.externalLink) updateBody.isDeleteExternalVideo = true
        } else if (!updateBody.video) updateBody.isDeleteVideoHLS = true
        dispatch('onlineCourseVideoLibrary/updateVideoSaveStatus', {}, {root: true})
      }
      const result = await dispatch('unit/updateUnit', updateBody, {root: true})
      dispatch('unit/setSelectedUnit', result, {root: true})
      message.success('Cập nhật thành công!')
      if (examTypes.includes(type)) await dispatch('draftUnitRescore/openPopupConfirm', {}, {root: true})
      return true
    } catch (err) {
      message.error(err)
    }
  },
  async updateDuration({dispatch, rootState}) {
    const {units, selectedUnit} = rootState.unit
    const {type, data} = rootState.draftUnit
    const detailData = data
    if (detailData != {}) {
      const exams = await dispatch('exam/fetchExams', {course: selectedUnit.course}, {root: true})
      let durationVimeo = utils.getVimeoDuration(units, selectedUnit)
      let durationExam = utils.getExamDuration(exams, selectedUnit)
      let unitDuration = 0
      if (type === 'vimeo' && detailData.vimeo && detailData.vimeo.duration) {
        unitDuration = durationVimeo + durationExam * 60 + detailData.vimeo.duration
      } else unitDuration = durationVimeo + durationExam * 60
      try {
        if (selectedUnit && selectedUnit.course)
          dispatch(
            'onlineCourse/updateCourse',
            {
              id: selectedUnit.course,
              duration: unitDuration,
            },
            {root: true}
          )
      } catch (error) {
        console.log(error)
      }
    }
  },
  async updateUnitExam({dispatch, rootState, getters}) {
    const detailInfo = getters.getDataUnit
    const settings = {...getters.getUnitSetting, ...getters.getExamSetting}
    const isPartition = getters.getIsPartition
    const {type, isUpdateDataExam} = rootState.draftUnit
    const {selectedUnit} = rootState.unit
    if (!detailInfo) return
    let data = {}
    if (type === 'matrix') {
      data = await utils.convertMatrixBeforeSave(detailInfo, isPartition)
      const error = await utils.validateMatrixBeforeSave(data, settings)
      if (error) {
        message.error(error)
        return
      }
    }
    let exam
    try {
      exam = await dispatch(
        'exam/createOrUpdateExam',
        {
          exam: selectedUnit.exam,
          data: {
            ...data,
            course: selectedUnit.course,
            vendorId: selectedUnit.vendorId,
            type: type === 'matrix' ? 'random-question-test' : type,
            settings: {
              ...settings,
              isUpdateDataExam: false,
            },
            startTime: utils.getDateFromSetting(settings, 'start'),
            endTime: utils.getDateFromSetting(settings, 'end'),
            resources: detailInfo.resources,
          },
        },
        {root: true}
      )
    } catch (error) {
      message.error('Network Error')
      return
    }
    if (isUpdateDataExam) {
      if (type === 'matrix' && settings.numberOfExam && exam.id) {
        const res = await dispatch('draftUnitMatrix/executeExamFormatrix', {settings, exam}, {root: true})
        if (!res) return false
      }
      if (type === 'test' || type === 'exercise' || type === 'reading-test' || type === 'listening-test') {
        if (!detailInfo.questions) return
        // check thiếu đáp án & check điểm + thời gian
        const validateMark = utils.validateMark(detailInfo.questions, settings)
        if (!validateMark) {
          message.error('Tổng điểm phải bằng 10 !')
          return false
        }
        const qInvalid = utils.validateQuestion(detailInfo.questions, type)
        if (qInvalid && qInvalid.length) {
          message.error(`Kiểm tra lại điểm, thời gian hoặc đáp án của ${qInvalid.join(',')}`)
          return false
        }

        const res = await dispatch('draftUnitTest/updateQuestions', exam, {root: true})
        if (!res) return false
      }
    }
    return exam._id
  },
  async validateScore({getters}) {
    const rules = getters.getRules
    const dataUnit = getters.getDataUnit
    if (rules.hasMinScore) {
      if (Number(rules.minScore) > 0 && Number(rules.minScore) <= Number(dataUnit.totalScore)) {
        return true
      } else {
        message.error('Điểm tối thiểu đang không phù hợp với thang điểm và số điểm của bài kiểm tra.')
        return false
      }
    } else return true
  },
  validateRangeTime({rootState}) {
    const {rangeTimeFromDate, rangeTimeFromHour, rangeTimeToDate, rangeTimeToHour, hasRangeTime, formRangeTime} =
      rootState.draftUnit
    if (hasRangeTime) {
      const startTime = dayjs(`${rangeTimeFromDate}T${rangeTimeFromHour}:00.000+07:00`).valueOf()
      const endTime = dayjs(`${rangeTimeToDate}T${rangeTimeToHour}:00.000+07:00`).valueOf()
      if (!formRangeTime) {
        message.error('Thời gian truy cập không hợp lệ !')
        return false
      } else if (endTime <= startTime) {
        message.error('Thời gian bắt đầu phải trước thời gian kết thúc !')
        return false
      } else return true
    }
    return true
  },
  validatePublicTime({rootState}) {
    const {hasPublicationTime, formPublicTime} = rootState.draftUnit
    if (hasPublicationTime) {
      if (!formPublicTime) {
        message.error('Thời gian công bố không hợp lệ !')
        return false
      }
    }
    return true
  },
  validatePlanPost({rootState}) {
    const {hasPlanPostUnit, formPlanPostTime} = rootState.draftUnit
    if (hasPlanPostUnit) {
      if (!formPlanPostTime) {
        message.error('Thời gian đăng bài không hợp lệ !')
        return false
      }
    }
    return true
  },
}

const mutations = {
  setInit(state, data) {
    return Object.keys(data).map((key) => {
      return (state[key] = data[key])
    })
  },
  setIsLoading(state, val) {
    return (state.isLoading = val)
  },
  setIsInitDraft(state, val) {
    return (state.isInitDraft = val)
  },
  setAttributes(state, obj) {
    const setUpdate = (name, val) => {
      if ((name === 'data' || name?.includes('data.')) && !state.isInitDraft) {
        state.isUpdateDataExam = true
      }
      if (name?.includes('.')) {
        const data = Object.assign({}, state)
        set(data, name, val)
        return (state = data)
      }
      return (state[name] = val)
    }
    if (Array.isArray(obj)) {
      obj.map((item) => {
        return setUpdate(item.name, item.value)
      })
    }
    return setUpdate(obj.name, obj.value)
  },
  setSettingExams(state, obj) {
    if (Array.isArray(obj)) {
      return obj.map((item) => {
        return (state.settingExams[item.name] = item.value)
      })
    }
    return (state.settingExams[obj.name] = obj.value)
  },
  setReviewConfig(state, obj) {
    return (state.reviewConfig[obj.name] = obj.value)
  },
  setReviewConfigDraft(state, obj) {
    return (state.reviewConfigDraft[obj.name] = obj.value)
  },
  resetDraft(state) {
    return (state = {
      ...state,
      ...defaultState(),
    })
  },
}

const getters = {
  getDraftType: (state) => {
    return state.type
  },
  getDraftUnit: (state) => {
    return state
  },
  getLoading: (state) => {
    return state.isLoading
  },
  getIsSubmit: (state) => {
    return state.isSubmit
  },
  getInfo: (state) => {
    const {id, title, syllabus, hasAttachment, hasDescription, description} = state
    return {
      title,
      syllabus,
      hasAttachment,
      hasDescription,
      description,
      id,
    }
  },
  getDiscusion: (state) => {
    const {mustApproveDiscussion, canEnableDiscussion} = state
    return {
      mustApproveDiscussion,
      canEnableDiscussion,
    }
  },
  getHasPlanPostUnit: (state) => {
    const {hasPlanPostUnit, postDate, postTime} = state
    return {
      hasPlanPostUnit,
      postDate,
      postTime,
    }
  },
  getRangeTime: (state) => {
    const {hasRangeTime, rangeTimeFromDate, rangeTimeFromHour, rangeTimeToDate, rangeTimeToHour} = state
    return {
      hasRangeTime,
      rangeTimeFromDate,
      rangeTimeFromHour,
      rangeTimeToDate,
      rangeTimeToHour,
    }
  },
  getPublicTime: (state) => {
    const {hasPublicationTime, publicDate, publicHour} = state
    return {
      hasPublicationTime,
      publicDate,
      publicHour,
    }
  },
  getRules: (state) => {
    const {
      submitCount,
      isRankingHidden,
      hideResult,
      hideAnswer,
      enableDetectFocusout,
      hasMinScore,
      minScore,
      screenRatioHaft,
    } = state
    return {
      submitCount,
      isRankingHidden,
      hideResult,
      hideAnswer,
      enableDetectFocusout,
      hasMinScore,
      minScore,
      screenRatioHaft,
    }
  },
  getUnitSetting: (_, getters) => {
    const {getRules, getDiscusion, getPublicTime, getRangeTime, getHasPlanPostUnit} = getters
    return {
      ...getRules,
      ...getDiscusion,
      ...getPublicTime,
      ...getRangeTime,
      ...getHasPlanPostUnit,
    }
  },
  isExternalLink: (state) => {
    return state.isExternalLink
  },
  getIsDraft: (state) => {
    return state.isDraft
  },
  getExamSettingNotUpdate: (state) => {
    return state.settingExams
  },
  getExamSetting: (state) => {
    return {
      isPartition: state.isPartition,

      isRandomQuestion: state.isRandomQuestion,
      isUseFactor: state.isUseFactor,
      isQuestionOneByOne: state.isQuestionOneByOne,
      isDisablePrevious: state.isDisablePrevious,
      shuffleAnswers: state.shuffleAnswers,

      selectedTime: state.selectedTime,
      selectedTypeScore: state.selectedTypeScore,
      expiredTime: state.expiredTime,
      numberOfExam: state.numberOfExam,
      isUpdateDataExam: state.isUpdateDataExam,

      isResultReviewShown: state.isResultReviewShown,
      isReportExam: state.isReportExam,
      flashcardType: state.flashcardType,
    }
  },
  getAllSettings: (_, getters) => {
    const {getUnitSetting, getExamSetting, isExternalLink, getIsDraft} = getters
    return {
      ...getUnitSetting,
      ...getExamSetting,
      isExternalLink,
      isDraft: getIsDraft,
    }
  },
  getDraft: (state) => {
    const {isDraft, unpublished} = state
    return {
      isDraft,
      unpublished,
    }
  },
  getUnitId: (state) => {
    return state.id
  },
  getDataUnit: (state) => {
    return state.data
  },
  getIsPartition: (state) => {
    return state.isPartition
  },
  typeScore10Mark: (state) => {
    return state.selectedTypeScore === '10-mark'
  },
  resetScoreDefault: (state) => {
    return state.selectedTypeScore !== '10-mark' ?? new Date().getTime()
  },
  openTimePart: (state) => {
    return state.openTimePart
  },
  openScorePart: (state) => {
    return state.openScorePart
  },
  getLoadingSubmit: (state) => {
    return state.loadingSubmit
  },
  getReviewConfig: (state) => {
    return state.reviewConfig
  },
  getReviewConfigDraft: (state) => {
    return state.reviewConfigDraft
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
